import swiper from 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.min'

// Featured Slider
var feature_slider = new swiper('.feature-slider-container', {
    direction: 'horizontal',
    autoHeight: false,
    loop: false,
    parallax: true,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.feature-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Content Slider
  var large_content_slider = new swiper('.swiper-container', {
    direction: 'horizontal',
    autoHeight: false,
    loop: false,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    pagination: {
      el: '.content-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Vertical Slider
var vertical_slider = new swiper('.vertical-slider-container', {
    direction: 'vertical',
    speed: 800,
    parallax: true,
    watchOverflow: true,
    pagination: '.vertical-slider-controls',
    paginationClickable: true
});
$(".vertical-slider-controls").on('click', 'span', function(){
    vertical_slider.slideTo($(this).index(), 800);
});
// BBQ Friends Slider
var content_slider = new swiper('.bbq-friends-slider-container', {
  direction: 'horizontal',
  effect: 'slide',
  speed: 900,
  loop: true,
  watchOverflow: true,
  navigation: {
    nextEl: '.bbq-friends-swiper-next',
    prevEl: '.bbq-friends-swiper-prev',
  },
});