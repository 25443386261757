// import scrollMagic from 'scrollmagic'
// import { TweenMax } from 'gsap'
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';


// var controller = new scrollMagic.Controller({globalSceneOptions: {triggerHook: "onEnter", duration: "100%"}});

// // build scenes
// new scrollMagic.Scene({triggerElement: ".modules .content-block"})
// 	.setTween(".modules .content-block .basic", {y: "80%", ease: Linear.easeNone})
// 	.addIndicators()
// 	.addTo(controller);