// import main SASS file
import '../../styles/scss/style.scss'

// Import Foundation
import 'foundation-sites'

// import JS modules to compile
import './init-foundation'
import './wp-foundation'
import './modules/fancybox'
import './modules/functions'
import './modules/sliders'
import './modules/scrollMagicAnimations'
import './modules/parallax'